import { Injectable, inject } from '@angular/core';
import { IClub } from '@beathletics/api-interfaces';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import type { EventType, NewCategory, NewCountry, NewFederation } from '@prisma/client';
import { produce } from 'immer';
import { tap } from 'rxjs';
import { SharedApiService } from './shared-api.service';
import {
  LoadCategories,
  LoadClubs,
  LoadCountries,
  LoadEventTypes,
  LoadFederations,
  LoadSharedData,
} from './shared-data.action';

export interface SharedDataStateModel {
  categories: NewCategory[];
  clubs: IClub[];
  countries: NewCountry[];
  eventTypes: EventType[];
  federations: NewFederation[];
}

@State<SharedDataStateModel>({
  name: 'shared',
  defaults: {
    categories: [],
    clubs: [],
    countries: [],
    eventTypes: [],
    federations: [],
  },
})
@Injectable()
export class SharedDataState implements NgxsOnInit {
  private apiService = inject(SharedApiService);
  private store = inject(Store);

  @Selector()
  static categories(state: SharedDataStateModel) {
    return state.categories;
  }

  @Selector()
  static clubs(state: SharedDataStateModel) {
    return state.clubs;
  }

  @Selector()
  static countries(state: SharedDataStateModel) {
    return state.countries;
  }

  @Selector()
  static eventTypes(state: SharedDataStateModel) {
    return state.eventTypes;
  }

  @Selector()
  static federations(state: SharedDataStateModel) {
    return state.federations;
  }

  @Selector()
  static foreignFederations(state: SharedDataStateModel) {
    return state.federations
      .filter((f) => f.abbr !== 'LBFA' && f.abbr !== 'VAL' && f.abbr !== 'LRBA-KBAB')
      .sort((a, b) => a.country.localeCompare(b.country));
  }

  @Action(LoadSharedData)
  loadSharedData() {
    this.store.dispatch(new LoadClubs());
    this.store.dispatch(new LoadCountries());
    this.store.dispatch(new LoadFederations());
  }

  @Action(LoadCategories)
  loadCategories(ctx: StateContext<SharedDataStateModel>) {
    const state = ctx.getState();
    if (!state.categories || state.categories.length < 1) {
      return this.apiService.getAllCategories().pipe(
        tap((categories) => {
          return ctx.setState(
            produce((draft: SharedDataStateModel) => {
              draft.categories = categories.sort((a, b) => (a.name > b.name ? 1 : -1));
            }),
          );
        }),
      );
    } else {
      return state.categories;
    }
  }

  @Action(LoadClubs)
  loadClubs(ctx: StateContext<SharedDataStateModel>) {
    const state = ctx.getState();
    if (!state.clubs || state.clubs.length < 1) {
      return this.apiService.getAllClubs().pipe(
        tap((clubs) => {
          return ctx.setState(
            produce((draft: SharedDataStateModel) => {
              draft.clubs = clubs.sort((a, b) => (a.abbr > b.abbr ? 1 : -1));
            }),
          );
        }),
      );
    } else {
      return state.clubs;
    }
  }

  @Action(LoadCountries)
  loadCountries(ctx: StateContext<SharedDataStateModel>) {
    const state = ctx.getState();
    if (!state.countries || state.countries.length < 1) {
      return this.apiService.getAllCountries().pipe(
        tap((countries) => {
          return ctx.setState(
            produce((draft: SharedDataStateModel) => {
              draft.countries = countries.sort((a, b) => (a.name > b.name ? 1 : -1));
            }),
          );
        }),
      );
    } else {
      return state.countries;
    }
  }

  @Action(LoadEventTypes)
  loadEventTypes(ctx: StateContext<SharedDataStateModel>) {
    const state = ctx.getState();
    if (!state.eventTypes || state.eventTypes.length < 1) {
      return this.apiService.getAllEventTypes().pipe(
        tap((eventTypes) => {
          return ctx.setState(
            produce((draft: SharedDataStateModel) => {
              draft.eventTypes = eventTypes.sort((a, b) => +a.type_id - +b.type_id);
            }),
          );
        }),
      );
    } else {
      return state.eventTypes;
    }
  }

  @Action(LoadFederations)
  loadFederations(ctx: StateContext<SharedDataStateModel>) {
    const state = ctx.getState();
    if (!state.federations || state.federations.length < 1) {
      return this.apiService.getAllFederations().pipe(
        tap((federations) => {
          return ctx.setState(
            produce((draft: SharedDataStateModel) => {
              draft.federations = federations;
            }),
          );
        }),
      );
    } else {
      return state.federations;
    }
  }

  ngxsOnInit(ctx: StateContext<SharedDataStateModel>) {
    this.store.dispatch(new LoadSharedData());
  }
}
