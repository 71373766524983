import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, computed, effect, inject, signal } from '@angular/core';
import type { KeycloakRole, KeycloakUser } from '@beathletics/api-interfaces';
import { Roles } from '@beathletics/api-interfaces';
import { getUserHigherRole } from '@beathletics/utils-shared';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Selector, Store, createPropertySelectors, select } from '@ngxs/store';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { BehaviorSubject, Observable, combineLatest, filter, map, of } from 'rxjs';
import { AuthStateModel } from './auth.model';
import { AuthState } from './auth.state';
import { KEYCLOAK_URL } from './front-auth.module';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  #store = inject(Store);
  platformId = inject(PLATFORM_ID);
  static authPropertySelectors = createPropertySelectors<AuthStateModel>(AuthState);
  keycloakRealm = 'beathletics';
  isReady = new BehaviorSubject(false);
  private http = inject(HttpClient);
  private keycloak = inject(KeycloakService);
  private keycloak_url = inject(KEYCLOAK_URL);
  isEnabled = false;

  constructor() {
    if (isPlatformBrowser(this.platformId)) {
      this.isEnabled = true;
      this.init();
    }
  }

  init() {
    if (isPlatformBrowser(this.platformId)) {
      this.keycloak.init({
        config: {
          url: `${this.keycloak_url}`,
          realm: 'beathletics',
          clientId: 'beathletics',
        },
        initOptions: {
          onLoad: 'check-sso',
          enableLogging: true,
          checkLoginIframe: true,
          silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
        },
        updateMinValidity: 60,
      });
      this.keycloak.keycloakEvents$.subscribe({
        next: (e) => {
          if (e.type === KeycloakEventType.OnAuthLogout) {
            this.logout();
          }
          if (e.type === KeycloakEventType.OnReady) {
            this.isReady.next(true);
          }
        },
      });
    }
  }

  @SelectSnapshot(AuthService.emailSelector)
  public emailSnapshot: string | undefined;
  private _profile$ = this.#store.select(AuthState.profile);
  private _roles$ = this.#store.select(AuthState.roles);
  private _validationStatusByRole$ = this.#store.select(AuthState.validationStatusByRole);

  @Selector([AuthService.authPropertySelectors.profile])
  static emailSelector(profile: KeycloakProfile | undefined) {
    return profile?.email;
  }

  getInstance() {
    return this.keycloak.getKeycloakInstance();
  }

  linkedClub = () =>
    computed(() => {
      const profile = select(AuthState.profile)();
      if ((profile?.attributes?.club as string[])?.[0]) {
        return (profile?.attributes?.club as string[])?.[0];
      }
      return undefined;
    });

  profile = select(AuthState.profile);

  getProfile() {
    return this._profile$.pipe(filter((profile) => !!profile));
  }

  signalProfile = this.#store.selectSignal(AuthState.profile);

  getUsername(): Observable<string | undefined> {
    return this.getProfile().pipe(map((profile) => (profile ? profile?.username : undefined)));
  }

  getUserClub() {
    return this._profile$.pipe(map((profile) => (profile ? (profile?.attributes?.club as string[])?.[0] : undefined)));
  }

  getHigherRoleAndProfile() {
    return combineLatest([this.getProfile(), this.getHigherRole()]).pipe(map(([profile, role]) => ({ profile, role })));
  }

  getAdminMenu() {
    return of([
      {
        link: ['/admin', 'dashboard'],
        label: 'Dashboard',
        icon: undefined,
        hasRole$: this.hasAtLeastOneRole([Roles.Admin_lbfa, Roles.Admin]),
      },
      {
        link: ['/admin', 'exports'],
        label: 'Exports',
        icon: undefined,
        hasRole$: this.hasAtLeastOneRole([Roles.Admin_lbfa, Roles.Admin]),
      },
      {
        link: ['/admin', 'clubs'],
        label: 'Liste des Clubs',
        icon: undefined,
        hasRole$: this.hasAtLeastOneRole([Roles.Admin_lbfa, Roles.Admin]),
      },
      {
        link: ['/admin', 'events'],
        label: 'Liste des Events',
        icon: undefined,
        hasRole$: this.hasAtLeastOneRole([Roles.Admin_lbfa, Roles.Admin]),
      },
      {
        link: ['/admin', 'athletes'],
        label: 'Liste des Athlètes',
        icon: undefined,
        hasRole$: this.hasAtLeastOneRole([Roles.Admin_lbfa, Roles.Admin]),
      },
      {
        link: ['/admin', 'event-types'],
        label: 'Liste des Event Types',
        icon: undefined,
        hasRole$: this.hasAtLeastOneRole([Roles.Admin_lbfa, Roles.Admin, Roles.Ja]),
      },
      {
        link: ['/admin', 'categories'],
        label: 'Liste des Catégories',
        icon: undefined,
        hasRole$: this.hasAtLeastOneRole([Roles.Admin_lbfa, Roles.Admin]),
      },
      {
        link: ['/admin', 'users'],
        label: 'Liste des Utilisateurs',
        icon: undefined,
        hasRole$: this.hasAtLeastOneRole([Roles.Admin_lbfa, Roles.Admin]),
      },
      {
        link: ['/admin', 'tasks'],
        label: 'Tâches exécutées',
        icon: undefined,
        hasRole$: this.hasAtLeastOneRole([Roles.Admin_lbfa, Roles.Admin]),
      },
      {
        link: ['/admin', 'cronTasks'],
        label: 'Orchestration des Tâches',
        icon: undefined,
        hasRole$: this.hasRole(Roles.Admin),
      },
      {
        link: ['/admin', 'uploads'],
        label: 'Chargement de données',
        icon: undefined,
        hasRole$: this.hasAtLeastOneRole([Roles.Admin_lbfa, Roles.Admin]),
      },
      {
        link: ['/admin', 'actions'],
        label: 'Historique des actions',
        icon: undefined,
        hasRole$: this.hasAtLeastOneRole([Roles.Admin_lbfa, Roles.Admin]),
      },
      {
        link: ['/admin', 'features'],
        label: 'Fonctionnalités',
        icon: undefined,
        hasRole$: this.hasAtLeastOneRole([Roles.Admin_lbfa, Roles.Admin]),
      },
    ]);
  }

  getAvailableValidations = () => this._validationStatusByRole$;

  getRoles() {
    return this._roles$.pipe(filter((role) => !!role));
  }

  getUserRoles() {
    const offAcc = 'offline_access' as Roles;
    const uma = 'uma_authorization' as Roles;
    return this._roles$.pipe(
      filter((role) => !!role),
      map((roles) => roles.filter((r) => r !== Roles.User && r !== offAcc && r !== uma)),
    );
  }

  getHigherRole() {
    return this._roles$.pipe(map((roles) => getUserHigherRole(roles)));
  }

  getRolesByUserId(userId: string) {
    return this.http.get(`/api/user-management/roles/user/${userId}`);
  }

  getAllExistingRoles() {
    return this.keycloak.getUserRoles();
  }

  logout() {
    this.keycloak.logout();
  }

  hasRole(roleToHave: Roles) {
    return this._roles$.pipe(map((roles) => roles.includes(roleToHave)));
  }

  hasOnlyRole(roleToHave: Roles) {
    return this._roles$.pipe(
      map((roles: string[]) => {
        const userRoles = roles.filter(
          (r) => r !== 'default-roles-beathletics' && r !== 'offline_access' && r !== '' && r !== 'uma_authorization',
        );
        return userRoles.length === 1 && userRoles[0] === roleToHave;
      }),
    );
  }

  hasOnlyOneOfRoles(rolesToHave: Roles[]) {
    return this._roles$.pipe(
      map((roles: string[]) => {
        const userRoles = roles.filter(
          (r) => r !== 'default-roles-beathletics' && r !== 'offline_access' && r !== '' && r !== 'uma_authorization',
        );
        return userRoles.length === 1 && rolesToHave.includes(userRoles[0] as Roles);
      }),
    );
  }

  hasAtLeastOneRole(roleToHave: Roles[]) {
    return this._roles$.pipe(
      map((roles) => {
        for (const role of roleToHave) {
          if (roles.includes(role)) {
            return true;
          }
        }
        return false;
      }),
    );
  }

  hasAllRoles(roleToHave: Roles[]) {
    return this._roles$.pipe(
      map((roles) => {
        for (const role of roleToHave) {
          if (!roles.includes(role)) {
            return false;
          }
        }
        return true;
      }),
    );
  }

  getAllUsers(): Observable<KeycloakUser[]> {
    const users = this.http.get<KeycloakUser[]>('/api/user-management/users/all');
    const role = this.getHigherRole();
    return combineLatest([users, role]).pipe(
      map(([users, role]) => {
        if (role !== Roles.Admin) {
          return users.filter((user) => !user.roles?.includes(Roles.Admin));
        }
        return users;
      }),
    );
  }

  getAllUsersByRole(role: string) {
    return this.http.get<KeycloakUser[]>(`/api/user-management/users/all?role=${role}`);
  }

  getUsersByEmails(emails: string[]) {
    return this.getAllUsers().pipe(
      map((users) =>
        users.filter((user) => {
          if (user?.email) return emails.includes(user.email);
          else return false;
        }),
      ),
    );
  }

  getSecretariesAndDelegatesWithClubAssigned() {
    const secretaries$ = this.getAllUsersByRole(Roles.Secretary);
    const delegates$ = this.getAllUsersByRole(Roles.Delegate);
    return combineLatest([secretaries$, delegates$]).pipe(
      map(([secretaries, delegates]) =>
        secretaries.concat(delegates).filter((user) => (user?.attributes?.club?.length || 0) > 0),
      ),
    );
  }

  sendActionEmail(userId: string, action: string, lifespanInSec?: number) {
    return this.http.put(`/api/user-management/execute-action/${userId}`, {
      name: action,
      lifespan: lifespanInSec,
    });
  }

  getAllRealmRoles(): Observable<KeycloakRole[]> {
    return this.http.get<KeycloakRole[]>('/api/user-management/roles/all');
  }

  addRoleToUserId(userId: string, role: KeycloakRole) {
    return this.http.post(`/api/user-management/user/${userId}/add-role`, role);
  }

  removeRoleByUserId(userId: string, role: KeycloakRole) {
    return this.http.delete(`/api/user-management/user/${userId}/remove-role`, {
      body: role,
    });
  }

  updateUserData(newUserData: KeycloakUser) {
    return this.http.put(`/api/user-management/user/${newUserData.id}/update`, newUserData);
  }

  getOrganizerOfEvent(eventNumber: string) {
    return this.http.get<{ type: 'club' | 'federation'; abbr: string } | null>(
      `/api/new-competition/organizer/${eventNumber}`,
    );
  }
}
