import {
  EPerfType,
  IAthlete,
  IResultToAthlete,
  ResultWithRelations,
  PrismaDisciplineImportType as DisciplineImportType,
  PrismaGender as Gender,
} from '@beathletics/api-interfaces';
import type { Results, NewResult, NewResultToAthlete, NewAthlete, EventType } from '@prisma/client';

import { startOfYear, endOfMonth, isWithinInterval, getYear, differenceInYears } from 'date-fns';
import { DICTIONARY_CATEGORIES, DICTIONARY_CATEGORIES_FULL } from './constants';

enum Month {
  January = 0,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December,
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getDateToEuropeanFormat(date: string) {
  const arr = date.split('-');
  return `${arr[2]}-${arr[1]}-${arr[0]}`;
}

export function getCategoryFromResult(result: Results) {
  let _cat = '';
  if (result.athleteCategory) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const cat = result.athleteCategory as any;
    _cat = cat.name;
  } else if (result.eventCategory) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const cat = result.eventCategory as any;
    _cat = cat.name;
  } else {
    _cat = 'Unknown Category';
  }
  if (_cat.length > 5) {
    const c = _cat.substring(0, 2).toUpperCase();
    if (c !== 'UN') return DICTIONARY_CATEGORIES[c];
    else return c;
  } else return _cat;
}

export function getFormattedDate(
  date: Date | string | undefined,
  element: 'weekday' | 'day' | 'month' | 'year' | 'all' = 'all',
) {
  if (date) {
    let _date: Date;
    if (date instanceof Date) {
      _date = date;
    } else {
      _date = new Date(date);
    }
    const weekday = new Intl.DateTimeFormat('fr-FR', { weekday: 'long' }).format(_date).substring(0, 3).toUpperCase();
    const day = _date.getDate() < 10 ? '0' + _date.getDate() : _date.getDate();
    const month = new Intl.DateTimeFormat('fr-FR', { month: 'long' }).format(_date).substring(0, 3);
    const year = +('' + _date.getFullYear()).substring(2);

    switch (element) {
      case 'weekday':
        return weekday;
      case 'day':
        return day;
      case 'month':
        return month;
      case 'year':
        return year;
      case 'all':
        return {
          weekday,
          day,
          month,
          year,
        };
      default:
        return '';
    }
  }
}

export function getActualAgeFromBirthDate(birthDate: Date | null | undefined) {
  if (birthDate) {
    const delta = Date.now() - new Date(birthDate).getTime();
    const age_date = new Date(delta);
    return Math.abs(age_date.getFullYear() - 1970);
  } else {
    return undefined;
  }
}

export function calculateAge(birthDate: string | Date): number {
  return differenceInYears(new Date(), new Date(birthDate));
}
export function getAgeAtStartOfAthleticYear(birthdate: Date) {
  //* Careful: athletic year = from 01/11/X to 31/10/X+1 !
  const now = new Date(Date.now());
  let startYear: number;
  if (now.getMonth() > 9) startYear = now.getFullYear();
  else startYear = now.getFullYear() - 1;

  const startOfAthleticYear = new Date(startYear, 10, 1);
  const delta = startOfAthleticYear.getTime() - new Date(birthdate).getTime();
  const age_date = new Date(delta);
  return Math.abs(age_date.getFullYear() - 1970);
}

export function findCategoryFromAthlete(athlete: IAthlete, getAbbrev = false) {
  if (!athlete.person?.birthDate || !athlete.person?.gender) {
    throw new Error('No birthdate or gender provided');
  }
  return findCategoryFromDateAndGender(new Date(athlete.person.birthDate), athlete.person?.gender, getAbbrev);
}

export function findCategoryFromDateAndGender(
  birthDate: Date,
  athleteGender: Gender,
  getAbbrev = false,
  dateToCompare?: Date,
) {
  if (!birthDate) {
    throw new Error('No birthdate provided');
  }

  const age = getActualAgeFromBirthDate(birthDate) || 0; //TODO : check if it's ok ?
  const athleticSeason = getAthleticSeasonFromDate(dateToCompare || new Date());
  const seasonYears = athleticSeason.split(' - ').map(Number);
  const birthYear = birthDate.getFullYear();
  const gender = athleteGender === Gender.Female ? 'F' : 'M';

  if (birthYear > seasonYears[0] - 6 || birthYear > seasonYears[1] - 6) {
    // too young to be in any category !
    return `ERROR: TOO YOUNG (${athleteGender} ${age})`;
  } else if (age < 35) {
    // category calculated by range between the athlete birth year and the actual athletic season years
    if (birthYear === seasonYears[0] - 6 || birthYear === seasonYears[1] - 6) {
      return getAbbrev ? 'KAN ' + gender : `Kangourou (${gender})`;
    } else if (birthYear === seasonYears[0] - 8 || birthYear === seasonYears[1] - 8) {
      return getAbbrev ? 'BEN ' + gender : `Benjamin${gender === 'F' ? 'e' : ''} (${gender})`;
    } else if (birthYear === seasonYears[0] - 10 || birthYear === seasonYears[1] - 10) {
      return getAbbrev ? 'PUP ' + gender : `Pupille (${gender})`;
    } else if (birthYear === seasonYears[0] - 12 || birthYear === seasonYears[1] - 12) {
      return getAbbrev ? 'MIN ' + gender : `Minime (${gender})`;
    } else if (birthYear === seasonYears[0] - 14 || birthYear === seasonYears[1] - 14) {
      return getAbbrev ? 'CAD ' + gender : `Cadet${gender === 'F' ? 'te' : ''} (${gender})`;
    } else if (birthYear === seasonYears[0] - 16 || birthYear === seasonYears[1] - 16) {
      return getAbbrev ? 'SCO ' + gender : `Scolaire (${gender})`;
    } else if (birthYear === seasonYears[0] - 18 || birthYear === seasonYears[1] - 18) {
      return getAbbrev ? 'JUN ' + gender : `Junior${gender === 'F' ? 'e' : ''} (${gender})`;
    } else if (
      birthYear === seasonYears[0] - 20 ||
      birthYear === seasonYears[1] - 20 ||
      birthYear === seasonYears[0] - 21 ||
      birthYear === seasonYears[1] - 21
    ) {
      return 'U23 ' + (gender === 'F' ? 'W' : 'M');
    } else {
      return getAbbrev ? 'SEN ' + gender : `Senior${gender === 'F' ? 'e' : ''} (${gender})`;
    }
  } else {
    const genderEn = gender === 'F' ? 'W' : 'M';
    // category calculated by birthdate (at age change)
    if (age < 40) return genderEn + 35;
    else if (age < 45) return genderEn + 40;
    else if (age < 50) return genderEn + 45;
    else if (age < 55) return genderEn + 50;
    else if (age < 60) return genderEn + 55;
    else if (age < 65) return genderEn + 60;
    else if (age < 70) return genderEn + 65;
    else if (age < 75) return genderEn + 70;
    else if (age < 80) return genderEn + 75;
    else if (age < 85) return genderEn + 80;
    else if (age < 90) return genderEn + 85;
    else if (age < 95) return genderEn + 90;
    else if (age < 100) return genderEn + 95;
    else return genderEn + 100 + '+';
  }
}

export function getAthleticSeasonsSinceYearToNow(firstYear: number, actualFirst = true) {
  const currentYear = new Date().getFullYear();
  const actualMonth = new Date().getMonth();
  const seasons = [];
  for (let year = firstYear; year <= currentYear; year++) {
    seasons.push(`${year} - ${year + 1}`);
  }
  // + remove last generated season if we aren't yet in INDOOR period (= start of new season)
  if (actualMonth < 10) {
    seasons.pop();
  }
  if (actualFirst) {
    return seasons.reverse();
  } else {
    return seasons;
  }
}

export function getAthleticSeasonFromDate(date: Date) {
  const _date = new Date(date);
  const year = _date.getFullYear();
  const month = _date.getMonth() + 1;
  if (month > 10 || month < 4) {
    // = INDOOR
    return `${month > 10 ? year : year - 1} - ${month > 10 ? year + 1 : year}`;
  } else {
    // = OUTDOOR
    return `${year - 1} - ${year}`;
  }
}

const ATHLETIC_SEASON_START_MONTH = 10; // Novembre (0-based index)
const ATHLETIC_SEASON_START_DAY = 1;
const ATHLETIC_SEASON_END_MONTH = 9; // Octobre (0-based index)
const ATHLETIC_SEASON_END_DAY = 31;

function getAthleticSeasonFromDate2(d: Date): string {
  const date = d;
  const currentYear = date.getFullYear();

  const seasonStart = startOfYear(new Date(currentYear, ATHLETIC_SEASON_START_MONTH, ATHLETIC_SEASON_START_DAY));
  const seasonEnd = endOfMonth(new Date(currentYear + 1, ATHLETIC_SEASON_END_MONTH, ATHLETIC_SEASON_END_DAY));

  const isCurrentSeason = isWithinInterval(date, {
    start: seasonStart,
    end: seasonEnd,
  });
  const year = isCurrentSeason ? currentYear : currentYear - 1;

  return `${year} - ${year + 1}`;
}

export function getActualAthleticsSeasonDates(otherDate?: Date) {
  const now = otherDate ? new Date(otherDate) : new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  if (month > 10 || month < 4) {
    // = INDOOR
    return {
      from: new Date(month > 10 ? year : year - 1, 10, 1),
      to: new Date(month > 10 ? year + 1 : year, 2, 31),
    };
  } else {
    // = OUTDOOR
    return {
      from: new Date(year, 3, 1),
      to: new Date(year, 9, 31),
    };
  }
}

export function getLeaderBoardInitData(fromDate?: Date): {
  season: 'I' | 'O';
  year: number;
} {
  const season = getActualAthleticsSeasonVenue(fromDate);
  const now = fromDate ? new Date(fromDate) : new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const day = now.getDate();
  if (season === 'O' || (season === 'I' && month < 11)) {
    return { season, year: year - 1 };
  } else {
    if (month === 11 && day <= 15) {
      return { season: 'O', year: year - 1 };
    }
    return { season, year };
  }
}

export function getSeasonBestYear(fromDate?: Date): number {
  const season = getActualAthleticsSeasonVenue();
  const now = fromDate ? new Date(fromDate) : new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  if (season === 'O' || (season === 'I' && month < 11)) {
    return year - 1;
  } else {
    return year;
  }
}

const testInclusive = isWithinInterval(new Date('2021-01-20T20:30:00.000Z'), {
  start: new Date('2021-01-20T20:30:00.000Z'),
  end: new Date('2021-01-29T21:30:00.000Z'),
});

export function getActualAndPreviousAthleticsSeasonInterval() {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  if (month > 10) {
    return {
      from: new Date(year - 1, 10, 1),
      to: new Date(year + 1, 9, 31),
    };
  } else {
    return {
      from: new Date(year - 2, 10, 1),
      to: new Date(year, 9, 31),
    };
  }
}

export function getActualAthleticsSeasonVenue(fromDate?: Date) {
  const now = fromDate ? new Date(fromDate) : new Date();
  const month = now.getMonth() + 1;
  if (month < 11 && month > 3) {
    return 'O';
  } else {
    return 'I';
  }
}

export function getAthleticSeasonsFromResults(results: Results[]) {
  const athleticSeasons: string[] = [];
  for (const res of results) {
    const season = getAthleticSeasonFromDate(res.date);
    if (!athleticSeasons.includes(season)) {
      athleticSeasons.push(season);
    }
  }
  return athleticSeasons.sort((a, b) => {
    if (a < b) return 1;
    if (a > b) return -1;
    return 0;
  });
}

export function getSeasonsFromResults(results: IResultToAthlete[]) {
  const seasons: string[] = [];
  for (const res of results) {
    const date = res?.result?.discipline?.competition?.startDate;
    if (date) {
      const season = getAthleticSeasonFromDate(date);
      if (!seasons.includes(season)) {
        seasons.push(season);
      }
    }
  }
  return seasons.sort((a, b) => {
    if (a < b) return 1;
    if (a > b) return -1;
    return 0;
  });
}

export function getAthleteGenderFromResult(result: ResultWithRelations) {
  let gender = result?.athletes?.[0]?.gender || (result?.rawAthletes as any[])?.[0]?.gender;
  if (!gender) {
    const category = (result?.athleteCategory || result?.eventCategory) as {
      name: string;
      abbr?: string;
      master?: string;
    };
    const cat = getCategoryAbbrFromCatJson(category);
    if (cat.includes(' ')) gender = cat.split(' ')[1];
    else gender = cat.charAt(0);
  }
  if (gender === 'V') return 'F';
  else if (gender === 'W') return 'F';
  else return gender;
}

export function getEventCategoryFromJson(
  catJson:
    | {
        name: string;
        abbreviation?: string;
        abbr?: string;
        master?: string;
      }
    | {
        name: string;
        abbreviation?: string;
        abbr?: string;
        master?: string;
      }[],
) {
  if (Array.isArray(catJson)) {
    let combinedCat = '';
    for (const [i, cat] of catJson.entries()) {
      const _cat = getCategoryAbbrFromCatJson(cat);
      combinedCat += `${_cat}${i !== catJson.length - 1 ? ' / ' : ''}`;
    }
    return combinedCat;
  } else {
    return getCategoryAbbrFromCatJson(catJson);
  }
}

export function getCategoryAbbrFromCatJson(
  cat: {
    name: string;
    abbreviation?: string;
    abbr?: string;
    master?: string;
  },
  abbrWithGender = true,
): string {
  if (abbrWithGender) {
    if (cat?.master) return cat.master;
    else {
      if (cat?.name) {
        if (cat.name.length > 5) {
          const catArr = cat.name.split(' ');
          if (Array.isArray(catArr) && catArr.length === 2) {
            const c = catArr[0].substring(0, 2).toUpperCase();
            let g = catArr[1];
            if (g.length > 3) g = g.substring(0, 1).toUpperCase();
            if (g.includes('(')) g = g.replace('(', '');
            if (g.includes(')')) g = g.replace(')', '');
            if (c !== 'UN')
              return (
                DICTIONARY_CATEGORIES[c] +
                ' ' +
                (g.toUpperCase() === 'V' ? g.replace('V', 'F').toUpperCase() : g.toUpperCase())
              );
            else return c;
          } else {
            return cat.name;
          }
        } else return cat.name;
      } else if (Array.isArray(cat)) {
        return 'UNKNOWN';
        // TODO : handle case of array (= no athleteCategory issue)
      } else return 'UNKNOWN';
    }
  } else {
    if (Array.isArray(cat)) {
      const catArr = cat[0].name.split(' ');
      let c = '';
      if (catArr.length === 1) c = 'MA';
      else c = catArr[0].substring(0, 2).toUpperCase();
      return DICTIONARY_CATEGORIES_FULL[c];
    } else {
      if (cat?.master) return cat.master;
      const catArr = cat.name.split(' ');
      let c = '';
      if (catArr.length === 1) c = 'MA';
      else c = catArr[0].substring(0, 2).toUpperCase();
      return DICTIONARY_CATEGORIES_FULL[c];
    }
  }
}

export function findBestPerfFromResult(result: ResultWithRelations) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const res = result.result as any;
  if (res?.results && res.results.length > 0) {
    for (const [i, _result] of res.results.entries()) {
      if (_result?.['@_bestresult'] === '1' || i === res.results.length - 1) {
        return _result;
      }
    }
  }
}

export function getAllDisciplinesAndCategoriesFromResults(results: ResultWithRelations[]) {
  const disciplines: string[] = [];
  const categories: string[] = [];
  for (const res of results) {
    const _dis = res?.eventType?.name_fr || res?.name;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const cat = res?.athleteCategory || (res?.eventCategory as any);
    const _cat = getCategoryAbbrFromCatJson(cat);

    if (!disciplines.includes(_dis)) disciplines.push(_dis);
    if (!categories.includes(_cat)) categories.push(_cat);
  }
  return {
    disciplines: disciplines.sort((a, b) => {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    }),
    categories: categories.sort((a, b) => {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    }),
  };
}

export function getTimeStampFromTimeString(time: string) {
  let _time = time;
  if (_time.includes(`'`)) {
    _time = _time.replace(/'/gi, `"`);
  }
  if (time.includes(`:`)) {
    _time = _time.replace(/:/gi, `"`);
  }
  const arr = _time.split(`"`).reverse();
  const stamp =
    +arr[0] * 10 + +arr[1] * 1000 + (arr[2] ? +arr[2] * 60 * 1000 : 0) + (arr[3] ? +arr[3] * 60 * 60 * 1000 : 0);
  return stamp;
}

export function retrieveAthletesLiveIdsFromResultList(results: ResultWithRelations[]) {
  const liveIdArr: string[] = [];
  for (const result of results) {
    if (result.type === DisciplineImportType.relay) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      for (const athlete of result?.athletes || (result.rawAthletes as any[])) {
        const liveId = athlete?.liveId || null;
        if (liveId) {
          if (!liveIdArr.includes(liveId)) liveIdArr.push(liveId);
        }
      }
    } else {
      const liveId = (result?.rawAthletes as any[])?.[0]?.liveId || null;
      if (liveId) {
        if (!liveIdArr.includes(liveId)) liveIdArr.push(liveId);
      }
    }
  }
  return liveIdArr;
}

export function getArrayOfAthleteIdsFromResultList(
  results: (NewResult & {
    athletes: (NewResultToAthlete & {
      athlete: NewAthlete;
    })[];
  })[],
) {
  const liveIds: string[] = [];
  for (const result of results) {
    for (const data of result.athletes) {
      if (data.athlete.liveId) {
        if (!liveIds.includes(data.athlete.liveId)) liveIds.push(data.athlete.liveId);
      }
    }
  }
  return liveIds;
}

export function getPerfTypeFromEventType(eventType?: EventType | null) {
  if (eventType && eventType.result_type) {
    if (eventType.result_type === 'Distance') {
      return EPerfType.DISTANCE;
    } else if (eventType.result_type === 'Time') {
      return EPerfType.TIME;
    } else if (eventType.result_type === 'Points') {
      return EPerfType.POINTS;
    } else {
      return EPerfType.UNKNOWN;
    }
  } else {
    return EPerfType.UNKNOWN;
  }
}

export function getCatSortOrder(cat: string) {
  switch (cat) {
    case 'Kangourous (M)':
      return 1;
    case 'KAN M':
      return 1;
    case 'Benjamins (M)':
      return 2;
    case 'BEN M':
      return 2;
    case 'Pupilles (M)':
      return 3;
    case 'PUP M':
      return 3;
    case 'Minimes (M)':
      return 4;
    case 'MIN M':
      return 4;
    case 'Cadets (M)':
      return 5;
    case 'CAD M':
      return 5;
    case 'Scolaires (M)':
      return 6;
    case 'SCO M':
      return 6;
    case 'Juniors (M)':
      return 7;
    case 'JUN M':
      return 7;
    case 'Seniors (M)':
      return 8;
    case 'SEN M':
      return 8;
    case 'U23 (M)':
      return 9;
    case 'U23 M':
      return 9;
    case 'Masters (M)':
      return 29;
    case 'M35+':
      return 29;
    case 'Toutes catégories (M)':
      return 45;
    case 'TC M':
      return 45;
    case 'Kangourous (F)':
      return 51;
    case 'KAN F':
      return 51;
    case 'Benjamines (F)':
      return 52;
    case 'BEN F':
      return 52;
    case 'Pupilles (F)':
      return 53;
    case 'PUP F':
      return 53;
    case 'Minimes (F)':
      return 54;
    case 'MIN F':
      return 54;
    case 'Cadettes (F)':
      return 55;
    case 'CAD F':
      return 55;
    case 'Scolaires (F)':
      return 56;
    case 'SCO F':
      return 56;
    case 'Juniores (F)':
      return 57;
    case 'JUN F':
      return 57;
    case 'Seniores (F)':
      return 58;
    case 'SEN F':
      return 58;
    case 'U23 (W)':
      return 59;
    case 'U23 W':
      return 59;
    case 'Masters (F)':
      return 79;
    case 'W35+':
      return 79;
    case 'Toutes catégories (F)':
      return 95;
    case 'TC F':
      return 95;
    default:
      return 0;
  }
}
