import { Directive, Input, TemplateRef, ViewContainerRef, WritableSignal, effect, inject, signal } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AppFeatureService } from './appFeature.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[hasFeatureAccess]',
})
@UntilDestroy()
export class hasFeatureAccessDirective {
  private templateRef = inject(TemplateRef);
  private viewContainer = inject(ViewContainerRef);
  protected readonly appFeatureService = inject(AppFeatureService);

  _feature: WritableSignal<string | undefined> = signal(undefined);
  @Input()
  set hasFeatureAccess(feature: string) {
    this._feature.set(feature);
    console.log('hasFeatureAccess directive');
  }

  isShow = false;

  directiveEffect = effect(() => {
    // console.log('directiveEffect');
    const feature = this._feature();
    if (feature && this.appFeatureService.featureAccess()[feature]) {
      if (!this.isShow) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isShow = true;
      }
    } else {
      this.viewContainer.clear();
      this.isShow = false;
    }
  });
}
