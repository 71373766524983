<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="
      (isHandset$ | async) === false &&
      (roles.includes('admin') || roles.includes('admin_lbfa'))
    "
    >
    <mat-toolbar (click)="drawer.toggle()" class="cursor-pointer"
      ><mat-icon>menu</mat-icon>&nbsp; Menu</mat-toolbar
      >
      <mat-nav-list>
        @for (menuItem of menu; track menuItem) {
          @if (menuItem.hasRole$ | async) {
            <a
              mat-list-item
              [routerLink]="menuItem.link"
              >{{ menuItem.label }}</a
              >
            }
          }
        </mat-nav-list>
      </mat-sidenav>

      <mat-sidenav-content>
        <mat-toolbar id="main-toolbar" class="justify-evenly gap-2">
          @if (
            ((isHandset$ | async) ||
            roles.includes('admin') ||
            roles.includes('admin_lbfa')) &&
            !drawer.opened
            ) {
            <button
              type="button"
              aria-label="Toggle sidenav"
              mat-icon-button
              (click)="drawer.toggle()"
              >
              <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
          }
          <a id="main-title" class="flex" routerLink="/">BeAthletics</a> <span [matTooltip]="gitHash">{{gitTag}}</span>{{flavor}}
          <div class="flex-grow"></div>
          <div class="mr-4 text-base">{{ username }} [{{ roles }}]</div>

          @if (roles.includes('secretary')) {
            <button
              mat-mini-fab
              matTooltip="Exportation résultats club"
              class="toolbar-btn blue"
              [routerLink]="['/admin', 'exports']"
              >
              <mat-icon>data_exploration</mat-icon>
            </button>
          }
          @if (roles.includes('admin')) {
            <button
              mat-mini-fab
              matTooltip="Dev Utils"
              color="accent"
              class="toolbar-btn"
              [routerLink]="['/admin', 'devUtils']"
              >
              <mat-icon>developer_board</mat-icon>
            </button>
          }
          <button
            mat-mini-fab
            matTooltip="Dashboard"
            color="primary"
            class="toolbar-btn"
            [routerLink]="['/admin']"
            >
            <mat-icon>dashboard</mat-icon>
          </button>
          <button
            mat-mini-fab
            matTooltip="Home"
            class="toolbar-btn green"
            [routerLink]="['/']"
            >
            <mat-icon>home</mat-icon>
          </button>
          <button
            mat-mini-fab
            matTooltip="Logout"
            [color]="'warn'"
            class="toolbar-btn"
            (click)="emitLogout(true)"
            >
            <mat-icon>power_settings_new</mat-icon>
          </button>
        </mat-toolbar>
        <div class="main-content">
          <ng-content></ng-content>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
