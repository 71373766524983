import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { TranslocoModule } from '@jsverse/transloco';
import { FormlyModule } from '@ngx-formly/core';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { CdkAccordionModule } from '@angular/cdk/accordion';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '@angular/cdk/layout';
import { CdkMenuModule } from '@angular/cdk/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { TreeSelectModule } from 'primeng/treeselect';

import { DisciplineSorterPipe } from './pipes/discipline-sorter.pipe';
import { PerfFormatterPipe } from './pipes/perf-formatter.pipe';
import { PriceFormatterPipe } from './pipes/price-formatter.pipe';
import { TypeofPipe } from './pipes/typeof.pipe';
import { AutofocusDirective } from './utils/mat-input-autofocus.directive';

import { AthleteSearchBarComponent } from './components/athlete-search-bar/athlete-search-bar.component';
import { CellObservableRendererComponent } from './components/cell-observable-renderer/cell-observable-renderer.component';
import { CheckboxRendererComponent } from './components/checkbox-renderer/checkbox-renderer.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { EmailInputDialogComponent } from './components/email-input-dialog/email-input-dialog.component';
import { FormlyDialogComponent } from './components/formly-dialog/formly-dialog.component';
import { GridLoadingOverlayComponent } from './components/grid-loading-overlay/grid-loading-overlay.component';
import { GridNoRowOverlayComponent } from './components/grid-no-row-overlay/grid-no-row-overlay.component';
import { HeaderComponent } from './components/header/header.component';
import { MainContainerComponent } from './components/main-container/main-container.component';
import { MainFooterComponent } from './components/main-footer/main-footer.component';
import { MainHeaderComponent } from './components/main-header/main-header.component';
import { NotificationDialogComponent } from './components/notification-dialog/notification-dialog.component';
import { PrimeListSelectorComponent } from './components/prime-list-selector/prime-list-selector.component';
import { PrimeTreeSelectorComponent } from './components/prime-tree-selector/prime-tree-selector.component';
import { PublicSearchBarComponent } from './components/public-search-bar/public-search-bar.component';
import { QuickSelectFilterComponent } from './components/quick-select-filter/quick-select-filter.component';
import { RegisterAthleteDialogComponent } from './components/register-athlete-dialog/register-athlete-dialog.component';
import { RowEditBtnComponent } from './components/row-edit-btn/row-edit-btn.component';
import { StickyTopBarComponent } from './components/sticky-top-bar/sticky-top-bar.component';
import { TimeInputComponent } from './components/time-input/time-input.component';
import { ToggleChipListComponent } from './components/toggle-chip-list/toggle-chip-list.component';

export const WebComponents = [
  MatIconModule,
  VirtualScrollerModule,
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatListModule,
  MatMenuModule,
  MatAutocompleteModule,
  MatGridListModule,
  MatCardModule,
  MatSlideToggleModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatChipsModule,
  MatSelectModule,
  MatTabsModule,
  MatTableModule,
  MatStepperModule,
  MatProgressSpinnerModule,
  MatButtonToggleModule,
  MatExpansionModule,
  CdkAccordionModule,
  MatRadioModule,
  LayoutModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatTooltipModule,
  MatTreeModule,
  MatCheckboxModule,
  MatSortModule,
  TreeSelectModule,
  MultiSelectModule,
  DropdownModule,
  MatProgressBarModule,
  DragDropModule,
  CdkMenuModule,
  InfiniteScrollDirective,
];

@NgModule({
  imports: [
    CommonModule,
    ...WebComponents,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    FormlyModule.forChild(),
    TranslocoModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgOptimizedImage,
    HeaderComponent,
    MainContainerComponent,
    RowEditBtnComponent,
    CellObservableRendererComponent,
    MainHeaderComponent,
    MainFooterComponent,
    PublicSearchBarComponent,
    GridLoadingOverlayComponent,
    GridNoRowOverlayComponent,
    ToggleChipListComponent,
    QuickSelectFilterComponent,
    CheckboxRendererComponent,
    FormlyDialogComponent,
    PerfFormatterPipe,
    TypeofPipe,
    AthleteSearchBarComponent,
    ConfirmationDialogComponent,
    EmailInputDialogComponent,
    RegisterAthleteDialogComponent,
    PrimeTreeSelectorComponent,
    PrimeListSelectorComponent,
    TimeInputComponent,
    ContactFormComponent,
    NotificationDialogComponent,
    StickyTopBarComponent,
    PriceFormatterPipe,
    DisciplineSorterPipe,
    AutofocusDirective,
  ],
  exports: [
    HeaderComponent,
    MainContainerComponent,
    MainHeaderComponent,
    MainFooterComponent,
    ToggleChipListComponent,
    ...WebComponents,
    PerfFormatterPipe,
    TypeofPipe,
    NgOptimizedImage,
    AthleteSearchBarComponent,
    PrimeTreeSelectorComponent,
    PrimeListSelectorComponent,
    TimeInputComponent,
    ContactFormComponent,
    NotificationDialogComponent,
    StickyTopBarComponent,
    PriceFormatterPipe,
    DisciplineSorterPipe,
    AutofocusDirective,
  ],
  providers: [provideNgxMask()],
})
export class BeathleticsUiModule {}
