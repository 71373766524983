// * EVENT TYPES CODES

import {
  ECompetitionFeature,
  EResultFormType,
  PrismaDisciplineGroup as DisciplineGroup,
} from '@beathletics/api-interfaces';

export const EMAIL_VALIDATION_REGEX = /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/;

export const URL_VALIDATION_REGEX = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;

export const highJumpHeightValidationRegex = /^\d*[,.]\d{2}?$/;

export const CODES_HIGH_JUMPS = [16, 19, 525];

export const CODES_DISCIPLINES_WITH_TRIALS = [
  16, 17, 18, 19, 30, 21, 23, 26, 34, 20, 20, 62, 63, 64, 65, 67, 70, 71, 72, 57, 57, 58, 58, 59, 59, 60, 60, 61, 61,
  85, 86, 87, 87, 137, 163, 164, 168, 170, 171, 156, 180, 218, 528, 528, 540, 529, 544, 543, 531, 548, 547, 546, 532,
  534, 530, 539, 539, 538, 538, 537, 537, 536, 536, 535, 535, 541, 555, 549, 553, 550, 552, 554, 551, 551, 618, 595,
  596, 597, 594, 599, 598, 598, 592, 592, 605, 604, 603, 607, 613, 615, 606, 617, 611, 610, 609, 614, 614, 602, 602,
  601, 601, 600, 600, 599, 616, 68, 545, 608, 612, 542, 593, 22, 161,
];

export const CODES_ALL_RACES = [
  2, 3, 4, 6, 7, 8, 9, 10, 12, 27, 35, 36, 37, 43, 51, 99, 100, 101, 103, 104, 105, 107, 108, 109, 112, 120, 121, 124,
  125, 126, 129, 130, 132, 136, 148, 149, 217, 243, 244, 247, 248, 249, 250, 251, 253, 254, 258, 259, 260, 264, 265,
  266, 267, 501, 502, 504, 505, 506, 508, 509, 510, 511, 515, 516, 1, 5, 40, 42, 113, 133, 142, 522, 255, 256, 513, 514,
  518, 519, 520, 556, 557, 619, 523, 558, 559, 560, 561, 563, 564, 565, 567, 568, 562, 569, 570, 571, 573, 574, 576,
  578, 579, 575, 580, 581, 582, 584, 585, 586, 588, 589, 590, 583, 587, 591, 11, 41, 102, 106, 123, 127, 128, 131, 503,
  507, 512, 521, 524, 566, 572, 577,
];

export const DICTIONARY_CATEGORIES = {
  ['BE']: 'BEN',
  ['CA']: 'CAD',
  ['JU']: 'JUN',
  ['KA']: 'KAN',
  ['MA']: 'MASTER',
  ['MI']: 'MIN',
  ['PU']: 'PUP',
  ['SC']: 'SCO',
  ['SE']: 'SEN',
} as Record<string, string>;

export const DICTIONARY_CATEGORIES_FULL = {
  ['BE']: 'Benjamins',
  ['CA']: 'Cadets',
  ['JU']: 'Juniors',
  ['KA']: 'Kangourous',
  ['MA']: 'Masters',
  ['MI']: 'Minimes',
  ['PU']: 'Pupilles',
  ['SC']: 'Scolaires',
  ['SE']: 'Seniors',
} as Record<string, string>;

export const HJ_RESULT_ALTERNATIVE_CODE = ['DNS', 'DQ', 'NM', 'R', 'RC', 'YC', 'YCR'];

export const CATEGORY_ORDER = {
  ['Kangourous']: 1,
  ['Benjamins']: 2,
  ['Pupilles']: 3,
  ['Minimes']: 4,
  ['Cadets']: 5,
  ['Scolaires']: 6,
  ['Juniors']: 7,
  ['Seniors']: 8,
  ['Masters']: 9,
} as Record<string, number>;

export const VALIDATION_STATUS: { [key: string]: string } = {
  ['pending']: 'En attente',
  ['ja_approved']: 'Validé par le JA',
  ['ja_not_approved']: 'Refusé par le JA',
  ['h_certified']: "Certifié par l'homologation",
  ['h_not_certified']: "Refusé par l'homologation",
  ['invalid']: 'Invalide',
};

export const DISCIPLINE_GROUP_TO_FORM_TYPE: {
  [key in DisciplineGroup]: EResultFormType;
} = {
  [DisciplineGroup.Race]: EResultFormType.RACE,
  [DisciplineGroup.Throws]: EResultFormType.THROW_AND_LJ,
  [DisciplineGroup.HorizontalJumps]: EResultFormType.THROW_AND_LJ,
  [DisciplineGroup.VerticalJumps]: EResultFormType.HIGH_JUMP,
  [DisciplineGroup.CombinedDiscipline]: EResultFormType.COMBINED_TOTAL,
  [DisciplineGroup.Unknown]: EResultFormType.RACE,
};

export const COMPETITION_TYPES = [
  'Challenge BPM - Eric de Meu',
  'Championnat LBFA',
  'Championnat de Belgique',
  'Championnat international',
  'Championnat provincial',
  'Interclubs',
  'Kids Athletics',
  'Meeting international',
  'Meeting interne',
  'Meeting ouvert',
  'Meeting provincial',
];

export const AM_NEGATIVE_RESULT_VALUES = {
  [-1]: ECompetitionFeature.NO_MARK,
  [-2]: ECompetitionFeature.DID_NOT_FINISH,
  [-3]: ECompetitionFeature.DID_NOT_START,
  [-4]: ECompetitionFeature.DISQUALIFIED,
  [-7]: ECompetitionFeature.NO_MARK,
  [-9]: ECompetitionFeature.DID_NOT_START,
}; // ? what about -5 and -6 ?

export const CAT_IN_MASTER = {
  ['Male']: ['M35', 'M40', 'M45', 'M50', 'M55', 'M60', 'M65', 'M70', 'M75', 'M80', 'M85', 'M90', 'M95', 'M100+'],
  ['Female']: ['W35', 'W40', 'W45', 'W50', 'W55', 'W60', 'W65', 'W70', 'W75', 'W80', 'W85', 'W90', 'W95', 'W100+'],
};

export const CAT_IN_SENIOR = {
  ['Male']: ['SEN M', 'U23 M', ...CAT_IN_MASTER['Male']],
  ['Female']: ['SEN F', 'U23 W', ...CAT_IN_MASTER['Female']],
};

export const CAT_IN_TC = {
  ['Male']: ['CAD M', 'SCO M', 'JUN M', ...CAT_IN_SENIOR['Male']],
  ['Female']: ['CAD F', 'SCO F', 'JUN F', ...CAT_IN_SENIOR['Female']],
};
