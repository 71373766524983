import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MatSidenavContainer, MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { MatToolbar } from '@angular/material/toolbar';
import { MatIcon } from '@angular/material/icon';
import { MatNavList, MatListItem } from '@angular/material/list';
import { RouterLink } from '@angular/router';
import { MatIconButton, MatMiniFabButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'beathletics-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.scss'],
  standalone: true,
  imports: [
    MatSidenavContainer,
    MatSidenav,
    MatToolbar,
    MatIcon,
    MatNavList,
    MatListItem,
    RouterLink,
    MatSidenavContent,
    MatIconButton,
    MatTooltip,
    MatMiniFabButton,
    AsyncPipe,
  ],
})
export class MainContainerComponent {
  private breakpointObserver = inject(BreakpointObserver);
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay(),
  );

  @Input() username: string | undefined | null;
  @Input() roles!: string[];
  @Input() menu: any[] | undefined | null;
  @Output() logout = new EventEmitter();
  // {"FLAVOR":"dev","GIT_ABBRV":"08c3bf73","GIT_REFS":"HEAD -> main, origin/main, origin/HEAD","GIT_SANITIZED_SUBJECT_LINE":"test-index-nginx","GIT_BODY":"","GIT_AUTHOR_NAME":"Mica Hallin","GIT_AUTHOR_EMAIL":"mica@hallin.be","GIT_AUTHOR_DATE":"Thu, 12 Sep 2024 13:02:20 +0200"}
  flavor = process.env.FLAVOR || '';
  gitHash = process.env.GIT_ABBRV || '';
  gitTag = process.env.GIT_REFS || 'VERSION_NOT_FOUND';
  commitMessage = process.env.GIT_SANITIZED_SUBJECT_LINE || 'COMMIT_MESSAGE_NOT_FOUND';
  commitDate = process.env.GIT_AUTHOR_DATE || 'COMMIT_DATE_NOT_FOUND';
  emitLogout(value: boolean) {
    this.logout.emit(value);
  }
}
